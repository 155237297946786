<template>
  <van-tabs>
    <van-tab title="当前订单">
      <van-list class="p-4">
        <order
          v-for="order in currentOrders"
          :key="order.sale_id"
          :order="order"
        />
      </van-list>
    </van-tab>
    <van-tab title="历史订单">
      <van-list class="p-4" @load="fetchMore">
        <order
          v-for="order in historyOrders"
          :key="order.sale_id"
          :order="order"
        />
      </van-list>
    </van-tab>
  </van-tabs>
</template>

<script>
import order from './order.vue'
import { getOrderList, getOpenId } from '@/api'
import dayjs from 'dayjs'

export default {
  components: { order },
  data() {
    return {
      currentOrders: [],
      historyOrders: []
    }
  },

  mounted() {
    getOrderList({
      lsale_id: getOpenId()
    }).then(orders => {
      orders.sort((a, b) => {
        return (
          dayjs(b.SaleMain.OPDATE).valueOf() -
          dayjs(a.SaleMain.OPDATE).valueOf()
        )
      })
      this.currentOrders = orders.filter(order => {
        return dayjs(order.SaleMain.OPDATE).isSame(dayjs(), 'date')
      })
      this.historyOrders = orders.filter(order => {
        return dayjs(order.SaleMain.OPDATE).isBefore(dayjs(), 'date')
      })
    })
  },

  methods: {
    fetchMore() {
      // this.historyOrders = [...this.historyOrders, ...orders]
    }
  }
}
</script>

<style>
</style>