<template>
  <van-cell class="rounded mb-4" @click="redirectToDetail">
    <div class="flex justify-between">
      <div class="font-medium">订单编号：{{ order.SaleMain.sale_id }}</div>
      <div class="font-medium">{{ status }}</div>
    </div>
    <div class="text-xs text-gray-400 my-1">{{ order.SaleMain.OPDATE }}</div>
    <div class="flex items-stretch">
      <div class="flex-grow flex overflow-hidden">
        <van-image
          class="mr-1"
          v-for="item in order.SaleDetails"
          :key="item.code + item.kw"
          width="60"
          height="60"
          :src="item | getImageUrl"
        />
      </div>
      <div class="flex items-center ml-2 flex-shrink-0 text-gray-400">
        共{{ count }}件
      </div>
    </div>
    <div class="flex justify-end py-2">
      <div class="text-gray-400">合计：</div>
      <div class="text-base font-semibold">{{ order.SaleMain.pay_total }}</div>
    </div>
  </van-cell>
</template>

<script>
import { sum } from 'lodash'
export default {
  props: ['order'],

  computed: {
    count() {
      return sum(this.order.SaleDetails.map(item => item.qty))
    },

    status() {
      const status = this.order.SaleMain.yyd
      if (!status || status === 'UNPAY') {
        return '等待支付'
      }
      return '已支付'
    }
  },

  methods: {
    redirectToDetail() {
      this.$router.push({
        name: 'order',
        query: {
          sale_id: this.order.SaleMain.sale_id
        }
      })
    }
  }
}
</script>

<style>
</style>